import React, { Component } from 'react';
import Helmet               from 'react-helmet';
import Layout               from '../components/Layout/Layout';
import { graphql }          from 'gatsby';
import './contattaci.scss';
import Loader               from '../components/_ui/Loader/Loader';

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class Contattaci extends Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.state = {
      busy: false,
      feedbackMsg: null,
      name: null,
      message: null,
      telephone: null,
      email: null,
      age: null,
      cv: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /*handleSubmit(event) {
    // Do not submit form via HTTP, since we're doing that via XHR request.
    event.preventDefault();
    // Loop through this component's refs (the fields) and add them to the
    // formData object. What we're left with is an object of key-value pairs
    // that represent the form data we want to send to Netlify.
    const formData = {};
    Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value));

    // Set options for axios. The URL we're submitting to
    // (this.props.location.pathname) is the current page.
    const axiosOptions = {
      url: window.location.pathname,
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(formData),
    };

    // Submit to Netlify. Upon success, set the feedback message and clear all
    // the fields within the form. Upon failure, keep the fields as they are,
    // but set the feedback message to show the error state.
    axios(axiosOptions)
      .then(response => {
        this.setState({
          feedbackMsg: 'Form compilato con successo!',
        });
        this.domRef.current.reset();
      })
      .catch(err =>
        this.setState({
          feedbackMsg: 'Errore in fase di invio della form.',
        }),
      );
  }*/

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      busy: true,
    });
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => this.setState({
        busy: false,
        feedbackMsg: 'Form compilato con successo!',
      }))
      .catch(error => this.setState({
        busy: false,
        feedbackMsg: 'Errore in fase di invio della form.',
      }));
  }

  isEmailValid() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !this.state.email || emailRegex.test(this.state.email);
  }

  isPhoneValid() {
    const phoneRegex = /^3\d{8,9}$/;
    return !this.state.telephone || phoneRegex.test(this.state.telephone);

  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  uploadFile = _ => {
    document.getElementById("cvInput").click()
  }

  render() {

    return (

      <Layout categories={this.props.data.prismic.allMacrocategorys.edges.map(el => el.node)}
              documents={this.props.data.prismic.allDocumentss.edges[0].node}>
        <Helmet title={'Lavora con noi'} titleTemplate={`Assibro | %s`} meta={[
          {
            name: `description`,
            content: 'Assibro',
          },
          {
            property: `og:title`,
            content: 'Assibro',
          },
          {
            property: `og:description`,
            content: 'Assibro',
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: 'Assibro',
          },
          {
            name: `twitter:title`,
            content: 'Assibro',
          },
          {
            name: `twitter:description`,
            content: 'Assibro',
          },
        ]}/>
        <div className="contact-us">
          <div className="contact-us__hero"/>
          <div className="contact-us__content">
            <div className="contact-us__banner">
              <h1>Lavora&nbsp;con&nbsp;noi</h1>
              <p>Vuoi entrare a far parte del team di AssiBro? Mandaci un messaggio e verrai ricontattato al più
                presto!</p>
              <form className="contact-us__banner__form"
                    ref={this.domRef}
                    name="Contact Form"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={event => this.handleSubmit(event)}>
                {!!this.state.feedbackMsg && <p className="form-succeded">{this.state.feedbackMsg}</p>}

                {!this.state.feedbackMsg && (
                  <>
                    <input ref="form-name" type="hidden" name="form-name" value="Contact Form"/>
                    <div hidden={true} className="input-hidden">
                      <label>
                        Don’t fill this out:{" "}
                        <input name="bot-field" onChange={this.handleInputChange} />
                      </label>
                    </div>

                    <div>
                      <label>Nome e cognome</label>
                      <input onChange={this.handleInputChange} ref="name" type="text" name="name" required/>
                    </div>
                    <div>
                      <label>Email</label>
                      <input onChange={this.handleInputChange}
                             className={!this.isEmailValid() ? 'input-invalid' : ''}
                             ref="email"
                             type="email"
                             name="email"
                             required/>
                    </div>
                    <div>
                      <label>Telefono</label>
                      <input ref="telephone"
                             onChange={this.handleInputChange}
                             className={!this.isPhoneValid() ? 'input-invalid' : ''}
                             type="number"
                             name="telephone"
                             required/>
                    </div>
                    <div>
                      <label>Età</label>
                      <input ref="age" onChange={this.handleInputChange} type="number" name="age" required/>
                    </div>
                    <div>
                      <label>Curriculum Vitae</label>
                      <button type="button" className="ab-button-grey" onClick={this.uploadFile}>Scegli file</button>
                      <input ref="cv" id="cvInput" hidden={true} onChange={this.handleAttachment} type="file" name="cv" required/>
                    </div>
                    <div>
                      <label>Messaggio</label>
                      <textarea onChange={this.handleInputChange} rows="8" ref="message" name="message" required/>
                    </div>


                    <button disabled={!this.isPhoneValid() ||
                                      !this.isEmailValid() ||
                                      !this.state.name ||
                                      !this.state.message ||
                                      !this.state.cv ||
                                      !this.state.age ||
                                      this.state.busy} className="ab-button" type="submit">
                      {this.state.busy ? <Loader /> : 'Invia richiesta'}
                    </button>
                  </>
                )}
              </form>
            </div>

          </div>

        </div>
      </Layout>
    );
  }
}


export default Contattaci;

export const query = graphql`
    query ContattaciQuery {
        prismic {
            allMacrocategorys(sortBy: position_ASC) {
                edges {
                    node {
                        name
                        icon
                        _meta {
                            uid
                            id
                        }
                    }
                }
            }
            allDocumentss {
                edges {
                    node {
                        attachment_3_4 {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        general_conditions {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        privacy_policy {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        electronic_signature {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

